.highlight {
  .rouge-code {
    pre {
      color: $white;
    }
  }
}

.highlight {
  .rouge-gutter {
    &.gl {
      background-color: $gray-100;
      border: $border-width $gray-400 solid;
      text-align: center;

      pre {
        color: $gray-400;
      }
    }
  }
}