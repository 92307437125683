.contact {
  .icon {
    $contact-icon-width: 15px;
    $contact-icon-width-larger: $contact-icon-width * 1.5;

    width: $contact-icon-width;
    margin-bottom: ($contact-icon-width / 3);

    &-larger {
      width: $contact-icon-width-larger;
      margin-bottom: ($contact-icon-width-larger / 3)
    }

    &-white {
      fill: $white;
    }

    &-primary {
      fill: $primary;
    }
  }
}