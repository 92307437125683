.post {
  h2 {
    a {
      color: $black;

      &:hover {
        text-decoration: none;
      }

      &:after {
          content: '\e086';
          font-family: 'Icons';
          font-size: ($h2-font-size * .75);
          margin-left: ($spacer * .25);
      }
    }
  }

  img {
    border: ($border-width * 2) solid $border-color !important;
    border-radius: $border-radius !important;
  }
}