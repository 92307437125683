@import "variables";
@import "bootstrap/bootstrap";
@import "open-iconic";

@import "index";
@import "contact";
@import "post";
@import "code";

// Overwrite the error highlighting of Jekyll.
.highlight .err {
    background-color: transparent;
}